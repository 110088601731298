.App {
  font-family: "Roboto Mono", monospace;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  line-height: 1.2;
  text-align: left;
  font-style: normal;
  font-size: 1.2rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  overflow: hidden;
}

.App-light {
  background-color: white;
  color: black;
}

.App-light a {
  color: black;
}

.App-dark a {
  color: white;
}

.App-Dark {
  background-color: #1f1f1f;
}

p {
  font-family: "Roboto Mono", monospace;
}
li {
  font-family: "Roboto Mono", monospace;
}

link {
  font-family: "Roboto Mono", monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto Mono", monospace;
}

body {
  background: #1f1f1f;
  margin: 0;
  color: white;
  overflow-x: hidden;
}

button:hover {
  cursor: pointer;
  transform: scale(1.05);
  box-shadow: 0px 0px 10px #95f31d;
}

p,
a,
button {
  font-style: normal;
  font-size: 1.2rem;
}

h1 {
  font-style: normal;
  font-size: 3rem;
  margin: 0px;
}

h2 {
  font-size: 2rem;
  margin: 0px;
}

h3 {
  font-size: 1.5rem;
  margin: 0px;
}

h4,
h5,
h6 {
  font-style: normal;
  font-size: 1rem;
  margin: 0px;
}

/*Light-Mode#########################*/

.body-lightmode {
  background: white !important;
  margin: 0;
  color: black !important;
}
