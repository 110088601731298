.contact-card {
  margin: 80px 10px;
  max-width: 600px;
  min-height: 400px;
  border: #c0f37e 2px solid;
  border-radius: 20px;
}
.contact-card-header {
  background-color: #c0f37e;
  color: black;
  padding: 10px;
  border-radius: 15px 15px 0 0;
}

.contact-card-body {
  padding: 10px;
}
