/* BackToTopButton.css */
.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 100;
  cursor: pointer;
  background-color: #333;
  color: #fff;
  border: none;
  padding: 10px 12px;
  border-radius: 50%;
  font-size: 24px;
}

@media screen and (min-width: 768px) {
  .back-to-top {
    display: none;
  }
}
