.imprint h1 {
  margin: 30px 0px 20px 0px;
}

.imprint h2 {
  margin: 30px 0px 20px 0px;
}

.imprint h3 {
  margin: 30px 0px 20px 0px;
}

.imprint p {
  margin: 30px 0px 20px 0px;
  max-width: 600px;
}

.imprint {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.imprint-content {
  max-width: 1200px;
  padding: 0px 20px;
  margin: auto;
}

@media screen and (max-width: 750px) {
  .imprint h1,
  .imprint h2 {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 450px) {
  .imprint h1,
  .imprint h2,
  .imprint h3 {
    font-size: 1.2rem;
  }
}
